/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Open Navigation
  var navigation = function(){
    
    var nav = $('.menu-primary'),
        ham = $('.menu-mobile'),
        head = $('.header-main'),
        header = $('header.primary');

    ham.on('click', function() {

        nav.toggleClass('active');
        head.toggleClass('active');
        header.toggleClass('active');

    });

    var hero = $('.hero-home'),
        heroPage = $('.hero-page');

    if( hero.length || heroPage.length ) {

      $(window).on('scroll load', function() {

        var scroll = $(window).scrollTop();

        if (scroll >= 50) {

          if( header.hasClass('transparent') ) {

            header.removeClass('transparent');

          }

        } else {

          header.addClass('transparent');

        }
        
      });

    }


  };
  // -------------------

  // Product Carousel
  var productCarousel = function() {

    carousel = $('.product-carousel');

    if( carousel.length ) {

      carousel.slick({
        mobileFirst: true,
        slidesToShow: 1,
        arrows: false,
        dots: true,
        prevArrow: '<div class="carousel-prev"><span class="icon icon-arrow"></span></div>',
        nextArrow: '<div class="carousel-next"><span class="icon icon-arrow"></span></div>',
        responsive: [
          { 
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
              arrows: true,
              dots: false,
            }
          },
          {
            breakpoint: 860,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
            }
          },
        ]
      });
      
    }

  }
  // -------------------

  // Product Gallery
  var productGallery = function() {

    var gallery = $('.product-gallery .gallery'),
        galleryModal = $('.gallery-modal .gallery'),
        galleryNav = $('.product-gallery .gallery, .product-gallery .gallery-thumbs, .gallery-modal .gallery'),
        galleryThumbs = $('.product-gallery .gallery-thumbs');

    if( gallery.length ) {

      gallery.slick({
        mobileFirst: true,
        slidesToShow: 1,
        arrows: true,
        dots: false,
        fade: true,
        asNavFor: galleryNav,
        prevArrow: '<div class="carousel-prev with-bg"><span class="icon icon-arrow"></span></div>',
        nextArrow: '<div class="carousel-next with-bg"><span class="icon icon-arrow"></span></div>',
      });

      galleryModal.slick({
        mobileFirst: true,
        slidesToShow: 1,
        arrows: true,
        dots: false,
        fade: true,
        asNavFor: galleryNav,
        prevArrow: '<div class="carousel-prev with-bg"><span class="icon icon-arrow"></span></div>',
        nextArrow: '<div class="carousel-next with-bg"><span class="icon icon-arrow"></span></div>',
      });

      galleryThumbs.slick({
        mobileFirst: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        focusOnSelect: true,
        asNavFor: galleryNav,
        variableWidth: true,
        responsive: [
          { 
            breakpoint: 768,
            settings: {
              slidesToShow: 5
            }
          },
        ]
      });

      var modal = $('.gallery-modal'),
          galleryClick = $('.product-gallery .gallery img, .gallery-modal-open'),
          close = $('.gallery-modal .modal-close');

      galleryClick.on('click', function() {
        modal.addClass('active');
      });

      close.on('click', function() {
        modal.removeClass('active');
      });

      // Move into gallery slider
      $('.gallery-modal-open').appendTo(gallery);
      
    }

  }
  // -------------------

  // Product Carousel
  var testimonials = function() {

    var testimonials = $('.testimonials-carousel');

    if( testimonials.length ) {

      testimonials.slick({
        mobileFirst: true,
        slidesToShow: 1,
        arrows: false,
        dots: true,
        fade: true,
        prevArrow: '<div class="carousel-prev"><span class="icon icon-arrow"></span></div>',
        nextArrow: '<div class="carousel-next"><span class="icon icon-arrow"></span></div>',
        responsive: [
          { 
            breakpoint: 1025,
            settings: {
              arrows: true,
              dots: false,
            }
          },
        ]
      });
      
    }

  }
  // -------------------

  // Post Gallery
  var postGallery = function() {

    var gallery = $('.featured-image.gallery');

    if( gallery.length ) {

      gallery.slick({
        mobileFirst: true,
        slidesToShow: 1,
        arrows: true,
        dots: false,
        prevArrow: '<div class="carousel-prev with-bg"><span class="icon icon-arrow"></span></div>',
        nextArrow: '<div class="carousel-next with-bg"><span class="icon icon-arrow"></span></div>',
      });
    
    }

  }
  // -------------------

  // Ajax Filter
  var ajaxFilter = function() {

    var sidebar = $('.sidebar-filter');

    if( sidebar.length ) {

      var alm_is_animating = false; // Animating flag
      var productItems = $('.alm-listing');

      // Btn Click Event
      $('.alm-filter-nav li a').on('click', function(e){    
        e.preventDefault();  
        var el = $(this); // Our selected element     
        
        if(!el.hasClass('active') && !alm_is_animating){ // Check for active and !alm_is_animating  
           alm_is_animating = true;   
           el.parent().addClass('active').siblings('li').removeClass('active'); // Add active state
           
           var data = el.data(), // Get data values from selected menu item
               transition = 'fade', // 'slide' | 'fade' | null
               speed = '600'; //in milliseconds
               
           $.fn.almFilter(transition, speed, data); // Run the filter     
        }

        // Scroll to listing so its in view
        if (window.matchMedia("(max-width: 769px)").matches) {
          $('html,body').animate({ 
              scrollTop: productItems.offset().top-150
          }, 800);  
        }
      });

      // Clear
      $('.alm-clear .clear').on('click', function(e){    
        e.preventDefault();  

        var el = $(this);
        var currentDrop = $('.sortby-list .first .current');

        var data = el.data(), // Get data values from selected menu item
               transition = 'fade', // 'slide' | 'fade' | null
               speed = '600'; //in milliseconds
               
         $.fn.almFilter(transition, speed, data); // Run the filter   

         $('.alm-filter-nav li').removeClass('active');
         currentDrop.text('Newest');

      });

      // price
      $('.sortby-list .drop a').on('click', function(e){    
        e.preventDefault();  

        var el = $(this);

        var data = el.data(), // Get data values from selected menu item
               transition = 'fade', // 'slide' | 'fade' | null
               speed = '600'; //in milliseconds
               
         $.fn.almFilter(transition, speed, data); // Run the filter   

      });

      $.fn.almFilterComplete = function(){      
        alm_is_animating = false; // clear animating flag
      };

      // Open filter menu
      var filterOption = $('.filter-title.option');

      filterOption.on('click', function() {

        $(this).next('.alm-filter-nav').slideToggle(600);

      });

    } // sidebar.length   

  }
  // -------------------

  // Count toolbar filter
  var toolbarFilter = function() {

    // Count and update after filter
    $.fn.almComplete = function(){

      var count = $('.toolbar .results .count'),
          items = $('.product-item').length;

      count.text(items);

    };

    var list = $('.sortby-list'),
        drop = $('.sortby-list .drop'),
        current = $('.sortby-list .first .current');

    // Show Dropdown
    list.on('click', function(e) {
      e.preventDefault();
      drop.fadeToggle();
    });

    // Show Newest once clicked on option
    drop.find('a').on('click', function() {
      current.text($(this).text());
      $('.sortby-list .hidden').fadeIn();
    });

  }
  // -------------------

  // formReveal
  var formReveal = function() {

    var btn = $('.sell-form-btn'),
        form = $('.form-reveal');

    btn.on('click', function() {

      $(this).addClass('active');
      form.fadeIn();

      // scroll to view
      $('html,body').animate({ 
          scrollTop: form.offset().top-80
      }, 800);  
      
    });

  }
  // -------------------

  // AOS - Scroll Animations
  var aos = function() {

    AOS.init({
      duration: 800,
      disable: function() {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
      }
    });

  }
  // -------------------

  // Contact
  var contact = function() {

    var contactForm = $('.wpcf7-form');

    if( contactForm.length ) {

      // Tooltip
      var tooltip = $('.input-tooltip'),
          hover = $('label.show-tooltip');

      hover.on('mouseenter', function() {

        tooltip.fadeIn();

      });

      hover.on('mouseleave', function() {

        tooltip.fadeOut();

      });
      
    }

  }
  // -------------------

  // Disable right click on images
  var disableRightClick = function() {

    $('body').on('contextmenu', 'img', function(e){ return false; });

  }
  // -------------------

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        // JavaScript to be fired on all pages
        navigation();
        productCarousel();
        productGallery();
        testimonials();
        aos();
        contact();
        disableRightClick();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Single Post (news)
    'single_post': {
      init: function() {
        postGallery();
      }
    },
    // Post Type Archives
    'post_type_archive': {
      init: function() {
        ajaxFilter();
        toolbarFilter();
        formReveal();
      }
    },

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
